import { useEffect, useState } from 'react';
import { baseInstance } from './../../../modules/api/api'
import './../../../App.css';
import currentTime from '../../../modules/currentTime/currentTime';
import { useParams } from 'react-router-dom';

export default function Service(){
    const {id} = useParams();
    const [clickedData, setClickedData] = useState(null);

    // Click category
    const [isClicked, setIsClicked] = useState(parseInt(id) || 0);
    const clickMore = (el) => {
        setIsClicked(el);
        setCheckItems([]); // 글열림 초기화
        clickPageNumber(1) // 페이지 초기화
        setArrowNum(0)
    }

    // Click title to see content in Board
    const [checkItems, setCheckItems] = useState([]);
    const selectChecked = (id) => { 
        if (!checkItems.includes(id)) {
            setCheckItems(item => [...item, id]); 
        } else {
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // Pagination
    const onepage =  6;
    const totalPage = clickedData?.pages
    const pageArr = [];
    for (let i = 0; i < totalPage; i++) {
        pageArr.push(i+1);
    }
    const [currentPage, setCurrentPage] = useState(1);
    const clickPageNumber = (e) => {
        setCurrentPage(e);
        setCheckItems([])
    }
    const [arrowNum, setArrowNum] = useState(0);
    const batchOfPages = 5;
    const clickNextArrow = (e) => {
        console.log(totalPage,'t')
        console.log(batchOfPages, 'b')
        if (arrowNum < (totalPage - batchOfPages)) {
            setArrowNum(arrowNum + batchOfPages);
        }
    }
    const clickPrevArrow = (e) => {
        if (!arrowNum == 0) {setArrowNum(arrowNum - batchOfPages);}
    }
    
    // [API] Get data
    const fetchData = async () => {
        try {
            const resp = await baseInstance.get(`${
                isClicked === 0 ? `/notice`
                : (isClicked === 1 ? `/faq`
                : `/library`)
            }/?page=${currentPage}&size=${onepage}`)
            setClickedData(resp.data);
        } catch (err) {
            console.log('Error >>', err) 
        }
    }
    useEffect(() => {
        fetchData();
    }, [isClicked, currentPage])


    return(
        <>

            <div className='section-one service'>
                <div className='section-one__con notice__con'>

                    <div className='service__btn-con'>
                        <div className='service__btn-items'>
                            <ul>
                                {['공지사항', 'FAQ', '자료실'].map((a,i) => {
                                    return(
                                        <li
                                        className={`${isClicked == i ? 'service__btn-after' : ''}`}
                                        onClick={()=>{clickMore(i)}}>
                                            {a}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className='section__txt-con'>
                        <div className='section__txt-title' style={{color:'#3692FF'}}>
                            {isClicked === 0 ? '공지사항'
                            : (isClicked === 1 ? 'FAQ'
                            : '자료실')}
                        </div>
                        <div className='section__txt-sub'>
                            {isClicked === 0 ? '새 소식을 들어주세요'
                            : (isClicked === 1 ? '자주 들어오는 질문을 모았습니다'
                            : '자료')}
                        </div>
                    </div>

                    <table className='intro-board__table'>
                        <thead className='intro-board__thead'>
                            <tr>
                                <th>순번</th>
                                <th>제목</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody className='intro-board__tbody'>
                            {clickedData?.items?.map((a, i) => {
                                return(
                                    <>
                                        <tr>
                                            <td>{clickedData?.total - i -((currentPage - 1) * onepage)}</td>
                                            <td style={{cursor:'pointer'}}>
                                                <a className={`${checkItems.includes(i) ? 'intro-board__title-after' : ''}`}  onClick={()=>{selectChecked(i)}}>
                                                    {a.title}
                                                </a>

                                                <div className={`intro-board__content-hide ${checkItems.includes(i) ? 'intro-board__content-after' : ''}`}>
                                                    <pre className={`${checkItems.includes(i) ? 'intro-board__content-after' : ''}`} dangerouslySetInnerHTML={{__html: a.content}}>
                                                    </pre>               
                                                </div>
                                            </td>
                                            <td>{currentTime(new Date(a.date))}</td>
                                        </tr>
                                        
                                    </>
                                )
                            })}
                        </tbody>
                    </table>

                    <div className='board__page-number'>
                        <div className='board__page-arrow' onClick={()=>{clickPrevArrow()}}>&#8249;</div>
                        {pageArr?.slice(arrowNum,(arrowNum + batchOfPages)).map((a, i) => {
                            return(
                                <div
                                    style={{fontWeight: a == currentPage ? '600' :'300'}}
                                    onClick={() => {clickPageNumber(a)}}>
                                        {a}
                                </div>
                            )})}
                        <div className='board__page-arrow' onClick={()=>{clickNextArrow()}}>&#8250;</div>
                    </div>
                </div>
            </div>
        </>
    )
}

