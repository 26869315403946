import { useEffect, useState } from 'react';
import './../App.css';
import list from './../data/license/list.json'
import Apache2 from '../data/license/Apache2';
import MIT from '../data/license/MIT';
import SILOFL from '../data/license/SILOFL';

export default function License(){
    const licenseList = list.data;

    return(
        <>
            <div className='section-page'>
                <div className='section-page__header-con'>
                    <div className='section-page__hedaer-txt-con'>
                        <div>LICENSE</div>
                        <div>본 웹사이트에 사용된 오픈소스 소프트웨어의 라이선스에 대하여 고지합니다.</div>
                    </div>
                </div>
 
                <div className='license__con' style={{padding:'0'}}>
                    <ul className='license__grid-con'>
                        {
                            licenseList?.map((a, i) => {
                                return (
                                    <li className='license__grid-item'>
                                        <div className='license__source-name'><a href={a.url}>{a.name}</a></div>
                                        <div className='license__source-url'><a href={a.url}>{a.url}</a></div>
                                        <div className='license__source-type'>{a.type}</div>
                                        <div className='license__source-writer'>{a.writer}</div>
                                    </li>
                                )
                            })
                        }
                    </ul>


                    <div className='license__txt-con'>
                        <Apache2 />
                        <MIT />
                        <SILOFL />
                    </div>
                </div>   
            </div>
        </>
    )
}
