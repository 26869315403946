import { useCallback, useEffect, useRef, useState } from 'react';
import './../App.css';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from '../modules/api/api';
import inputData from '../modules/inputData/inputData';
import {useScroll} from '../modules/useScroll/useScroll';
import currentTime from '../modules/currentTime/currentTime';
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




export default function Intro(){

    // Change Object
    let [dataObj, setDataObj] = useState({});
    // GET API
    const getProduct = async () => {
        try {
            // const resp01 = await baseInstance.get(`product`)
            // inputData(setDataObj,'product',resp01.data);

            const resp02 = await baseInstance.get(`notice/?page=1&size=6`)
            inputData(setDataObj,'notice',resp02.data);

            // const resp03 = await baseInstance.get(`company`)
            // inputData(setDataObj,'partner',resp03.data.partner.urls);
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        getProduct();
    }, [])

    // Slick
    let slickRef = useRef();
    // const previous = useCallback(() => slickRef.current.slickPrev(), []); // Cutomize arrows
    // const next = useCallback(() => slickRef.current.slickNext(), []); // Cutomize arrows
    const settings = {
        dots: false,
        fade: true,
        arrows: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
    };

    // Click title to see content in Board
    const [checkItems, setCheckItems] = useState([]);
    const selectChecked = (id) => { 
        if (!checkItems.includes(id)) {
            setCheckItems(item => [...item, id]); 
        } else {
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리
    

    return(
        <>
            <div className='intro-hero'>
                <div className='intro-hero__bg'>
                    <img src='images/hero.jpeg' />
                    <div className='intro-hero__bg-blue'></div>
                </div>
                
                <div className='section-one__con intro-hero__con'>
                    <div className='intro-hero__txt-con'>
                        <div className='intro-hero__txt-title'>
                            윤전자
                        </div>
                        <div className='intro-hero__txt-sub'>
                            한차원 업그레이드 된 기술을 바탕으로 제작한<br/>윤전자만의 제품을 소개합니다. 

                        </div>
                        <div className='intro-hero__btn-wrap'>
                            <a href='/product'>제품 보러가기</a>
                        </div>
                    </div>

                    <div className='intro-hero__slick-con'>
                        <Slider className='intro-hero__slick'
                        {...settings}
                        ref={slickRef}
                        >
                            {['01', '02', '03'].map((a, i) => {
                            // {dataObj?.product?.map((a, i) => {
                                return(
                                    <img src={`/images/hero${a}.png`} />
                                    // <img src={a.images?.urls[0]} />
                                    )
                                })
                            }
                        </Slider>

                        {/* Customize arrows */}
                        {/* <div className='intro-hero__slick-arrow-con'>
                            <div onClick={previous}>
                                <img
                                    src={"/icons/pre-arrow.png"}
                                    alt={"pre-arrow"}
                                />
                            </div>
                            <div onClick={next}>
                                <img
                                    src={"/icons/next-arrow.png"}
                                    alt={"next-arrow"}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className={`section-one intro-book up--start + ${useScroll('.intro-book', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con intro-book__con'>
                    <ul className='intro-book__txt-con none-pc'>
                        <li className='intro-book__txt-title'>
                            엘리베이터 핸드북
                        </li>
                    </ul>

                    <div className='intro-book__img-wrap'>
                        <img src='/images/mockup.png' decoding='async'/>
                    </div>

                    <ul className='intro-book__txt-con'>
                        <li className='intro-book__txt-title none-mo'>
                            엘리베이터 핸드북
                        </li>
                        <li className='intro-book__txt-detail'>
                            승강기 고장,<br/>
                            에러확인과 조치방법을<br/>
                            한번에<br/><br/>

                            제조사, 기종 상관없이<br/>
                            에러 전체 검색
                        </li>
                        <div className='intro-book__btn-wrap'>
                            <a href='/book'>핸드북 바로가기</a>
                        </div>
                    </ul>
                </div>
            </div>

            <div className={`section-one intro-board up--start + ${useScroll('.intro-board', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con intro-board__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title' style={{color:'#3692FF'}}>
                            공지사항
                        </div>
                        <div className='section__txt-sub'>
                            새 소식을 들어주세요
                        </div>
                    </div>

                    <table className='intro-board__table'>
                        <thead className='intro-board__thead'>
                            <tr>
                                <th>순번</th>
                                <th>제목</th>
                                <th>등록일</th>
                            </tr>
                        </thead>
                        <tbody className='intro-board__tbody'>
                            {dataObj?.notice?.items.map((a, i) => {
                                return(
                                    <tr>
                                        <td>{dataObj?.notice?.total - i}</td>
                                        <td style={{cursor:'pointer'}}>
                                            <a className={`${checkItems.includes(i) ? 'intro-board__title-after' : ''}`}
                                            onClick={()=>{selectChecked(i)}}>
                                                {a.title}
                                            </a>

                                            <div className={`intro-board__content-hide ${checkItems.includes(i) ? 'intro-board__content-after' : ''}`}>
                                                    <pre className={`${checkItems.includes(i) ? 'intro-board__content-after' : ''}`}  dangerouslySetInnerHTML={{__html: a.content}}>
                                                    </pre>       
                                                </div>
                                        </td>
                                        <td>{currentTime(new Date(a.date))}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <div className='intro-board__more'>
                        <a href='/service'>
                            전체보기
                        </a>
                    </div>
                </div>
            </div>

            <div className={`section-one intro-partners up--start + ${useScroll('.intro-partners', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con intro-partner__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title' style={{color:'#3692FF'}}>
                            파트너사
                        </div>
                        <div className='section__txt-sub'>
                            윤전자를 신뢰하는 파트너사를 소개합니다.
                        </div>
                    </div>

                    <div className='intro-partners__swiper-con'>
                        <Swiper
                            modules={[Autoplay]}
                            spaceBetween={displayMo.matches ? 30 : 50}
                            slidesPerView={displayMo.matches ? 3 : 5}
                            autoplay={{delay: 0, disableOnInteraction: false}} // Slides flow softly without stop
                            speed={displayMo.matches ? 2500 : 5000} // Slides flow softly without stop
                            loop={true} // Slides flow softly without stop
                            loopAdditionalSlides={1} // Slides flow softly without stop
                            className='swiper-wrapper' // Slides flow softly without stop
                            >
                                {['01', '02', '03', '04','01', '02', '03', '04'].map((a,i) => {
                                // {dataObj?.partner?.map((a,i) => {
                                    return(
                                        <SwiperSlide><img src={`/images/partner${a}.png`} decoding="async"/></SwiperSlide>
                                    )
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}