import { useEffect, useState } from 'react';
import './../../../App.css';

export default function Book(){




    return(
        <>
            <div style={{paddingTop: "60px"}}>
                <iframe src="https://www.yoonelecbook.com" height="1500px" width="100%"></iframe>
            </div>
        </>
    )
}

