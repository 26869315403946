import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../App.css';


export default function Header(props){    
    // scroll event
    const [clickHamburger, setClickHamburger] = useState(null);

    // 햄버거 버튼 클릭시 메뉴 보이기
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };


    return (
        <>
            <header className={`header header-changed header-unclick`}>
                <div className='header__con'>
                    <ul className='header__logo-con'>
                        <li className='none-pc'></li>
                        <li className='header__logo'>
                            <a href='/'>
                                <img src='/images/logo.png' decoding="async"/>
                                <span>YOON ELECTRONICS</span>
                            </a>
                        </li>
                        <li className='header__hamburger'><a><img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/></a></li>
                    </ul>
                    
                    <ul className='header__category-con'>
                        <li>
                            <a href='/'>
                                HOME
                            </a>
                        </li>
                        <li>
                            <a href='/company/introduction'>
                                회사 소개
                            </a>
                        </li>
                        <li>
                            <a href='/product'>
                                제품 소개
                            </a>
                        </li>
                        <li>
                            <a href='/service'>
                                서비스
                            </a>
                        </li>
                        <li>
                            <a href='/book' style={{color:'#0066A5'}}>
                                핸드북
                            </a>
                        </li>
                    </ul>
                </div>
            </header>

            <div className={`header-mo ${clickHamburger ? 'left-0' : '' }`}>
                <div className='header-mo__con'>
                    <ul className='header-mo__category-con'>
                        <li>
                            <a href='/company/introduction'>
                                회사 소개
                            </a>
                        </li>
                        <li>
                            <a href='/product'>
                                제품 소개
                            </a>
                        </li>
                        <li>
                            <a href='/service'>
                                서비스
                            </a>
                        </li>
                        <li>
                            <a href='/book' style={{color:'#0066A5'}}>
                                핸드북
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <header className='header-app-mo'> 
                <div className='header-app-mo__menu-con'>
                    <ul className='header-app-mo__menu-item'>
                        <a href='/'>
                            <li>홈</li>
                        </a>
                    </ul>
                    <ul className='header-app-mo__menu-item'>
                        <a href='/company/introduction'>
                            <li>회사 소개</li>
                        </a>
                    </ul>
                    <ul className='header-app-mo__menu-item'>
                        <a href='/product'>
                            <li>제품 소개</li>
                        </a>
                    </ul>
                    <ul className='header-app-mo__menu-item'>
                        <a href='/service'>
                            <li>서비스</li>
                        </a>
                    </ul>
                    <ul className='header-app-mo__menu-item'>
                        <a href='/book'>
                            <li><span>핸드북</span></li> 
                        </a>
                    </ul>
                </div>
            </header>



            <div className='bg-gray' style={{display: clickHamburger? 'block' : 'none'}} onClick={showList}></div>

            <div className='header-block'></div> {/* prevent for hiding other components */}
        </>
    )
}
