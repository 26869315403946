import { useEffect, useState } from 'react';
import './../../../App.css';
import { baseInstance, authInstance } from '../../../modules/api/api';
import { useNavigate } from 'react-router-dom';


export default function AdminSignin(){
    const navigate = useNavigate();

    // [API] signin
    const [keyInput, setKeyInput] = useState(null);
    const fetchAPI = async () => {
        try {
            const resp = await baseInstance.post('/admin',{admin_key: keyInput})
            .then ((resp) => {
                localStorage.setItem('access_token', resp.data.access_token)
                localStorage.setItem('refresh_token', resp.data.refresh_token)
                navigate('/admin/list')
                window.location.reload()
            })
        } catch (err) {
            if (err.response.status === 403) {
                alert('올바른 키 값이 아닙니다.')
            } else {
                alert('서버 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            console.log('Error >>', err)
        }
    }


    return(
        <>
            <div className='admin'>
            <img src='/images/hero.jpeg' decoding='async' />
                <div className='admin-signin__con'>
                    <img src='/images/logo.png' decoding='async'/>
                    <div className='admin-signin__input-item'>
                        <input
                            type= 'password'
                            onChange={(e) => {setKeyInput(e.target.value);}}
                            className={`signup2__answer-input`}
                            placeholder='admin-key'
                        />
                    </div>
                    <div className='admin-signin__btn' onClick={()=>{fetchAPI()}}>로그인</div>
                </div>
            </div>
        </>
    )
}