import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Intro, Footer, Header,
        Introduction,
        Product,
        Service,
        License,
        AdminList, AdminSignin,
        BoardWrite, BoardModify,
        ProductWrite, ProductModify,
        HistoryWrite, HistoryModify,
        Book
        } from './routes';


function App() {
  return (
    
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/company/introduction" element={<Introduction />} />

            <Route path="/product" element={<Product />}  />
            <Route path="/product/:id" element={<Product />}  />

            <Route path="/service" element={<Service />}  />
            <Route path="/service/:id" element={<Service />}  />

            <Route path="/book" element={<Book />}  />

            <Route path="/license" element={<License />} />

            <Route path="/admin">
              <Route path="list" element={<AdminList />}  />
              <Route path="" element={<AdminSignin />}  />
              <Route path="board/write" element={<BoardWrite />}  />
              <Route path="board/modify/:id" element={<BoardModify />}  />

              <Route path="product/write" element={<ProductWrite />}  />
              <Route path="product/modify/:id" element={<ProductModify />}  />

              <Route path="history/write" element={<HistoryWrite />}  />
              <Route path="history/modify/:id" element={<HistoryModify />}  />
            </Route>
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
