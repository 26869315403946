import { useEffect, useState } from 'react';
import './../../../App.css';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from 
'../../../modules/api/api';
import inputData from '../../../modules/inputData/inputData';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useParams } from 'react-router-dom';


export default function Product(){
    const {id} = useParams();

    // for mobile
    const displayMo = matchMedia("screen and (max-width: 1023px)"); // js 미디어쿼리

    // Click category
    const [isClicked, setIsClicked] = useState(id || 0);
    const [dataFlt, setDataFlt] = useState(null);
    const clickMore = (el) => {
        setIsClicked(el);
        if (el == '전체보기') {
            const filtering = dataObj.product
            setDataFlt(filtering)
        } else {
            const filtering = dataObj.product?.filter(function(value){
                return (value.category == el)
            })
            setDataFlt(filtering)
        }        
        setIndex(0) // Image index
        clickPageNumber(1) // 페이지 초기화
        setArrowNum(0)
    }

    // Change Object
    let [dataObj, setDataObj] = useState({});

    // Pagination
    const onepage = displayMo.matches ? 6 : 10;
    const totalPage = dataObj.data?.pages;
    const pageArr = [];
    for (let i = 0; i < totalPage; i++) {
        pageArr.push(i+1);
    }
    const [currentPage, setCurrentPage] = useState(1);
    const clickPageNumber = (e) => {
        setCurrentPage(e);
        setIndex(0);
    }
    const [arrowNum, setArrowNum] = useState(0);
    const batchOfPages = 5;
    const clickNextArrow = (e) => {
        console.log(totalPage,'t')
        console.log(batchOfPages, 'b')
        if (arrowNum < (totalPage - batchOfPages)) {
            setArrowNum(arrowNum + batchOfPages);
        }
    }
    const clickPrevArrow = (e) => {
        if (!arrowNum == 0) {setArrowNum(arrowNum - batchOfPages);}
    }

    // GET API
    const getAPI = async () => {
        try {
            const resp = await baseInstance.get(`product/category/?category=${isClicked}&page=${currentPage}&size=${onepage}`)
            console.log(resp.data)
            inputData(setDataObj,'data',resp.data);
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        getAPI();
    }, [isClicked, currentPage])
    useEffect(() => {
        clickMore(isClicked);
    }, [dataObj.product])

    // // Extract Category from DB
    // const [refresh, setRefresh] = useState(1);
    // const arrangeData = () => {
    //     const menu = []
    //     for (let i = 0; i < dataObj.data?.length; i++ ){
    //         // Extract Category data from DB
    //         const menu_exist = dataObj.data[i]?.category
    //         // Add Category that doesn't exist in an Array
    //         if (!menu.includes(menu_exist)) {
    //             menu.push(menu_exist)
    //         }
    //     }
    //     inputData(setDataObj, 'menu', menu)
    //     if (refresh == 1) {
    //         setTimeout(function(){
    //             setRefresh(refresh + 1)
    //         }, 500)
    //     }
    // }
    // useEffect(() => {
    //     arrangeData();
    // }, [refresh, dataObj.data])

    // Select Item
    const [index, setIndex] = useState(0)
    const [contentIndex, setContentIndex] = useState(0)
    const findIndex = (i) => {
        setIndex(i)
        setContentIndex(i + ((currentPage - 1) * onepage))
    }

    


    return(
        <>

            <div className='section-one product'>
                <div className='section-one__con notice__con'>

                    <div className='service__btn-con'>
                        <div className='service__btn-items'>
                            <ul>
                                {['비상통화장치', '도어컨트롤러', '비상제어 시스템'].map((a,i) => {
                                    return(
                                        <li
                                        className={`${isClicked == i ? 'service__btn-after' : ''}`}
                                        onClick={()=>{clickMore(i)}}>
                                            {a}
                                        </li>
                                    )
                                })} 
                            </ul>
                            <ul>
                                {['통신제어 시스템', '안내장치', '기타 제품'].map((a,i) => {
                                    return(
                                        <li
                                        className={`${isClicked == (i+3) ? 'service__btn-after' : ''}`}
                                        onClick={()=>{clickMore(i+3)}}>
                                            {a}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        {/* <select
                            onChange={(e)=>{clickMore(e.target.value)}}
                        >
                            {['비상통화장치', '도어컨트롤러', '비상제어 시스템', '통신제어 시스템', '안내장치', '기타 제품'].map((a,i)=>{
                                return(<option value={i}>{a}</option>)
                            })}
                        </select> */}
                    </div>

                    <div className='section__txt-con'>
                        <div className='section__txt-title' style={{color:'#3692FF'}}>
                            {['비상통화장치', '도어컨트롤러', '비상제어 시스템', '통신제어 시스템', '안내장치', '기타 제품'][isClicked]}
                        </div>
                        <div className='section__txt-sub'>
                            윤전자의 제품을 만나보세요
                        </div>
                    </div>

                    <ul className='product__img-con'>
                        {dataObj?.data?.items.map((a,i)=>{
                            return(
                                <li onClick={()=>{findIndex(i)}}>
                                    <img
                                        src={a.images?.urls[0]}
                                    />
                                    <span className={i == index ? 'product-after' : ''}>{a.product_name}</span>
                                </li>
                            )
                        })}                        
                    </ul>
                    <div className='board__page-number'>
                        <div className='board__page-arrow' onClick={()=>{clickPrevArrow()}}>&#8249;</div>
                        {pageArr?.slice(arrowNum,(arrowNum + batchOfPages)).map((a, i) => {
                            return(
                                <div
                                    style={{fontWeight: a == currentPage ? '600' :'300'}}
                                    onClick={() => {clickPageNumber(a)}}>
                                        {a}
                                </div>
                            )})}
                        <div className='board__page-arrow' onClick={()=>{clickNextArrow()}}>&#8250;</div>
                    </div>

                    <ul className='product__detail-con'>
                        <pre
                            dangerouslySetInnerHTML={{__html: dataObj?.data?.items[index]?.detail}}
                        ></pre>
                    </ul>
                </div>
            </div>
        </>
    )
}

// 