import { useEffect, useState } from 'react';
import './../../../App.css';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from 
'../../../modules/api/api';
import inputData from '../../../modules/inputData/inputData';
import {useScroll} from '../../../modules/useScroll/useScroll';
// GoogleMap
import GoogleMap from '../../../components/googleMap/GoogleMap';


export default function Introduction(){

    // Change Object
    let [dataObj, setDataObj] = useState({});
    // GET API
    const getAPI = async () => {
        try {
            const resp = await baseInstance.get(`history/?page=1&size=100`)
            inputData(setDataObj,'history',resp.data.items);
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        getAPI();
        arrangeData();
    }, [])

    const [refresh, setRefresh] = useState(1);
    const arrangeData = () => {
        const years = []
        for (let i = 2018; i < 2030; i++ ){
            const filtering = dataObj.history?.filter(function(value){
                return (parseInt(value.year) == i)
            })
            inputData(setDataObj,i,filtering) // 각연도별 연혁을 Object로 저장
            if (filtering?.some(a => a?.year == i)) {
                years.push(i) // 연혁이 있는 연도만 추출
            }
        }
        inputData(setDataObj,'years',years.reverse())
        // 연산식이 많아서 연산전에 렌더링이 끝나는 문제 -> 일정 시간 뒤 리렌더링
        if (refresh == 1) {
            setTimeout(function(){
                setRefresh(refresh + 1)
            }, 500) 
        }
    }
    useEffect(() => {
        arrangeData();
    }, [refresh])

    

    return(
        <>
            <div className='introduction-hero'>
                <div className='introduction-hero__bg'>
                    <div className='introduction-hero__bg-blue'></div>
                </div>

                <div className='introduction-hero__con'>
                    <div className='introduction-hero__txt-con'>
                        <div className='introduction-hero__txt-title'>
                            고객을 위한 가치,<br/>소통과 고객만족경영
                        </div>
                        <div className='introduction-hero__txt-title-mo'>
                            <span>고객을 위한 가치</span><br/>
                            소통<br/>
                            그리고<br/>
                            고객만족경영
                        </div>
                        <div className='introduction-hero__txt-sub'>
                            윤전자는 엘리베이터를 통해 고객과 소통하고,<br/>
                            필요한 자원에 대한 즉각적인 대응을 통한 <br className='br-mo'/>
                            고객만족경영을 최대 가치로 생각합니다.
                        </div>
                    </div>
                </div>
            </div>

            <div className={`section-one introduction-years ceo up--start + ${useScroll('.ceo', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con introduction-years__con'>
                    <div className='introduction-years__txt-con'>
                        <div className='introduction-years__header-title'>
                            대표 인사말
                        </div>
                        <div className='introduction-years__txt-title'>
                            윤전자는 엘리베이터를 통해 고객과 소통하고,<br/>
                            필요한 자원에 대한 즉각적인 대응을 통한 <span>고객만족경영</span>을 최대 가치로 기업 경영을 하고 있습니다.<br/>
                            윤전자는 앞으로도 고객만족을 최우선으로 하는 우리의 가치를 기반으로,<br/>
                            더 나은 서비스로 고객 여러분과 함께 성장하고 소통할 수 있도록 최선을 다하겠습니다.
                        </div>
                    </div>
                </div>
            </div>

            <div className={`section-one introduction-years years up--start + ${useScroll('.years', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con introduction-years__con'>
                    <div className='introduction-years__txt-con'>
                        <div className='introduction-years__header-title'>
                            기업 연혁
                        </div>
                    </div>
                    <div className='introduction-years__content-con'>
                            {dataObj.years?.map((a,i) => {
                                return (
                                   <>
                                    <ul className='introduction-years__content-item'>
                                        <li>{a}</li>
                                        <ul> 
                                            {dataObj?.[a].map((a,i) => {
                                                return <li>{a.month} |&nbsp;<span dangerouslySetInnerHTML={{__html: a.detail}}/></li>
                                            })} 
                                        </ul>
                                    </ul>
                                   </>   
                                )
                            })}                            
                    </div>
                </div>
            </div>

            <div className={`section-one introduction-map up--start + ${useScroll('.introduction-map', 0.70).isShow ? 'up--end1' : ''}`}>
                <div className='section-one__con introduction-map__con'>
                    <div className='section__txt-con'>
                        <div className='section__txt-title' style={{color:'#3692FF'}}>
                            오시는 길
                        </div>
                    </div>
                    <div className='introduction-map__content-con'>
                        <div className='introduction-map__map-wrap'>
                            <GoogleMap />
                        </div>
                        <div className='introduction-map__txt-con'>
                            <ul>
                                <li>주소</li>
                                <li>경기도 군포시 공단로 140번길 52<br/>에이스하이테크시티군포 1410호</li>
                            </ul>
                            <ul>
                                <li>대표 번호</li>
                                <li>031-406-1011</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}