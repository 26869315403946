import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from '../../../modules/api/api';
import Editor from './../../../components/EditorComponent/EditorComponentSmall';
import inputData from './../../../modules/inputData/inputData'


export default function HistoryWrite(){
    const navigate = useNavigate();
    const category = localStorage.getItem('admin_selected')

    // Change Object
    let [dataObj, setDataObj] = useState({});  

    // Refresh Token
    const reAuthAPI = async () => {
        try {
            const resp = await reAuthInstance.get('/admin/refresh')
            .then ((resp) => {
                localStorage.setItem('access_token', resp.data.access_token)
            })
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        reAuthAPI();
    }, [])

    // POST API
    const today = new Date();
    const fetchAPI = async () => {
        try {
            const resp = await authInstance.post( '/history'
                , {
                    year: dataObj.year,
                    month: dataObj.month,
                    detail: dataObj.detail,
                    date: String(today),
                }
            )
            alert('글이 등록되었습니다.')
            navigate('/admin/list')
            window.location.reload()
        } catch (err) {
            if (err.response.status === 400) {
                alert('토큰만료로 인해 재로그인이 필요합니다. (작성한 글은 임시저장 되었습니다)')
                navigate('/admin')
            } else {
                alert('서버 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            console.log('Error >>', err)
        }
    }

    // [component] writing editor
    function onEditorChange(value) { 
        inputData(setDataObj,'detail',value)
    }

    return(
        <>
            <div className='admin'>
                <img src='/images/hero.jpeg' decoding='async' />
                <div className='admin-write__con'>
                    <div className='board-write__board-name'>
                        <span>{category} 작성</span>
                    </div>
                    <div className='board-write__header'>
                        <div className='board-write__select-con'>
                            <select
                                onChange={(e) => {inputData(setDataObj,'year',e.target.value)}}
                            > 
                                {['연도 선택','2028','2027','2026','2025','2024','2023','2022','2021','2020','2019','2018'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </div>
                        <div className='board-write__select-con' style={{marginBottom:'15px'}}>
                            <select
                                onChange={(e) => {inputData(setDataObj,'month',e.target.value)}}
                            > 
                                {['월 선택','01','02','03','04','05','06','07','08','09','10','11','12'].map((a,i)=>{
                                    return(<option value={a}>{a}</option>)
                                })}
                            </select>
                        </div>

                    </div>
                    <div className='board-write__content-con'>
                        <div className='board-write__content-item'>
                            {/* Do not place <Editor /> in label tag. (font-size error) */}
                            <Editor value={dataObj.detail} onChange={onEditorChange} />
                        </div>

                        <div className='board-write__btn-submit'>
                            <li></li>
                            <span onClick={()=>{fetchAPI()}}>등록하기</span>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}