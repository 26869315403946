import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import { baseInstance, authInstance, reAuthInstance, fileAuthInstance } from '../../../modules/api/api';
import Editor from './../../../components/EditorComponent/EditorComponent';
import inputData from './../../../modules/inputData/inputData'

export default function BoardModify(){
    const navigate = useNavigate();
    const {id} = useParams();
    const category = localStorage.getItem('admin_selected')

    // Change Object
    let [dataObj, setDataObj] = useState({});
    
    // Refresh Token
    const reAuthAPI = async () => {
        try {
            const resp = await reAuthInstance.get('/admin/refresh')
            .then ((resp) => {
                localStorage.setItem('access_token', resp.data.access_token)
            })
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    // GET API
    const getAPI = async () => {
        try {
            const resp = await authInstance.get(
                category == '공지사항' ? `/notice/${id}`
                : (category == 'FAQ' ? `/faq/${id}`
                : `library/${id}`))
            inputData(setDataObj,'title',resp.data.title);
            inputData(setDataObj,'content',resp.data.content); // 여기서 state를 설정해줘야 에디터에서 불러오기할 때 오류가 안 뜸
            inputData(setDataObj,'file',resp.data.files)
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    useEffect(() => {
        reAuthAPI();
        getAPI();
    }, [])

 
    // Show file link
    const showFileLink = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        const nameArray = [];
        for (let i = 0; i < e.target.files.length; i++) { // 반복문으로 여러 파일을 데이터에 담기
            formData.append('files', e.target.files[i])
        }
        try {
            const resp = await fileAuthInstance.post('library/files', formData)
            console.log('success >>', resp.data.urls[0]);
            inputData(setDataObj,'file',resp.data.urls[0]);
        } catch (err) {
            console.log('Error >>', err)
        }
    }
    

    // Modify content API
    const fetchAPI = async () => {
        try {
            const resp = await authInstance.patch(
                category == '공지사항' ? `/notice/${id}`
                : (category == 'FAQ' ? `/faq/${id}`
                : `library/${id}`)
            , {
                title: dataObj.title,
                content: dataObj.content
        })
            alert('글이 등록되었습니다.')
            navigate('/admin/list')
            window.location.reload()
        } catch (err) {
            if (err.response.status === 400) {
                alert('토큰만료로 인해 재로그인이 필요합니다.')
                navigate('/admin')
            } else {
                alert('서버 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            console.log('Error >>', err)
        }
    }

    // [component] writing editor
    function onEditorChange(value) { 
        inputData(setDataObj,'content',value)
    }
    
    
    return(
        <>
            <div className='admin'>
                <img src='/images/hero.jpeg' decoding='async' />
                <div className='admin-write__con'>
                    <div className='board-write__board-name'>
                        <span>{category} 수정</span>
                    </div>
                    <div className='board-write__header'>
                        <div className='board-write__header-title'>
                            <label>
                                <input
                                    type= 'text'
                                    onChange={(e) => {inputData(setDataObj,'title',e.target.value)}}
                                    className='board-write__header-input'
                                    value={dataObj.title}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='board-write__content-con'>
                        <div className={`board-write__content-files`}>
                            <label for="input-file">파일 선택</label>
                            <span>{dataObj.file ? dataObj.file : '파일링크 보기'}</span>

                            <input
                            type= 'file'
                            onChange={(e) => {showFileLink(e)}}
                            id='input-file'
                            // multiple
                            />
                        </div>

                        <div className='board-write__content-item'>
                            {/* Do not place <Editor /> in label tag. (font-size error) */}
                            <Editor value={dataObj.content} onChange={onEditorChange} />
                        </div>
                        

                        <div className='board-write__btn-submit'>
                            <li></li>
                            <span onClick={()=>{fetchAPI()}}>수정하기</span>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}