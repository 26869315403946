import { useEffect } from 'react';
import './../App.css';

function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <ul className='footer__info-con'>
                        <div className='footer__info-name'>
                            주식회사 윤전자
                        </div>

                        {[
                            ['대표이사','이창규'],
                            ['사업자등록번호','409-86-54575'],
                            ['사업장 소재지', '경기도 군포시 공단로 140번길 52\n에이스하이테크시티군포 1410호'],
                            ['이메일', 'yoon.leecg@gmail.com'],
                            ['TEL', '031 - 406 - 1011'],
                            ['FAX', '031 - 409 - 1012']
                        ].map((a,i)=>{
                            return(
                                <li>
                                    <div>{a[0]}</div>
                                    <div>
                                        {a[1].split("\n").map((line)=>(<div>{line}</div>))}
                                    </div>
                                </li>
                        )})}
                    </ul>

                    <ul className='footer__menu-con'>
                        {[
                            [['회사소개','/company/introduction'],['대표 인사말','/company/introduction'],['회사연혁','/company/introduction'],['오시는길','/company/introduction']],
                            [['제품소개','/product'],['비상통화장치','/product/0'],['도어컨트롤러','/product/1'],['비상제어 시스템','/product/2'],['통신제어시스템','/product/3'],['안내장치','/product/4'],['기타 제품','/product/5']],
                            [['서비스','/service'],['공지사항','/service/0'],['고객지원','/service/1'],['자료실','/service/2']],
                            [['핸드북','/book']],
                        ].map((a1,i1)=>{
                            return(
                                <li>
                                    {a1.map((a2,i2)=>{
                                        return(
                                            <div><a href={a2[1]}>{a2[0]}</a></div>
                                        )
                                    })}
                                </li>
                        )})}
                    </ul>

                    <ul className='footer__logo-con none-pc'>
                        <li className='footer__logo'>
                            <img src='/images/logo.png' decoding="async"/>
                            <span>YOON<br/>ELECTRONICS</span>
                        </li>
                    </ul>

                    <div className='footer__law-con'>
                        <a href='/license'>오픈소스 라이선스</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;