import React, { useCallback, useEffect, useRef } from "react";

// Add <script> tag with API Key in 'index.html', before write this code.
function GoogleMap() {
  const mapRef = useRef(null);
  const myLatLng = [
    { lat: 37.357183, lng: 126.955510, label: '본사' }
  ];
 
  // Contents for clicking Markers[1/4]
  const contentString = [
    '본사'
  ]

  const initMap = useCallback(() => {
    // Why set setTimeout?
    // : To solve a problem that there's no window.google.maps
    setTimeout(function(){
      // Contents for clicking Markers[2/4]
      const infowindow01 = new window.google.maps.InfoWindow({
        content: contentString[0]
        // ariaLabel: '큰 제목',
      });

      const map = new window.google.maps.Map(mapRef.current, {
        center: myLatLng[0], // Central position of Map UI
        zoom: 16,
      });
  
      // Contents for clicking Markers[3/4]
      const marker01 = new window.google.maps.Marker({
        position: myLatLng[0],
        map,
        // label: 'A',
      });
  
      // Contents for clicking Markers[4/4]
      marker01.addListener("click", () => {
        infowindow01.open({
          anchor: marker01,
          map,
        });
      });
    }, 1000);
  }, [mapRef]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  return (
    <div
      className="map"
      style={{ width: "100%", height: "400px" }}
      ref={mapRef}
    ></div>
  );
}

export default GoogleMap;